
const Header = () => {
    const paypalUrl = "https://www.paypal.com/donate/?business=6BU9VY2WMLN5E&amount=5&no_recurring=0&item_name=Beati+pauperes+spiritu&currency_code=USD";

    return (
        <section className='header'>
            <div className="header__content">
                <h1>Hello, my name is Robel Siam welcome to my website</h1>

                <a href="mailto:robelesiam@gmail.com?subject=I just viewed your website it is brilliant you're a genius!" target="_blank" rel="noreferrer" className='button'>Email</a>
                <a href={paypalUrl} target="_self" rel="noreferrer" className='button'>Donate</a>
  
            </div>
        </section>
    );
}

export default Header;
